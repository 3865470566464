import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Avatar } from '@twilio-paste/avatar';
import { Paragraph } from '@twilio-paste/paragraph';
import { Alert } from '@twilio-paste/alert';
import { Menu, MenuButton, SubMenuButton, MenuItem, MenuGroup, MenuSeparator, useMenuState } from '@twilio-paste/menu';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { ChevronDownIcon } from '@twilio-paste/icons/esm/ChevronDownIcon';
import { subMenuExample } from '../../../component-examples/MenuExamples.ts';
import { Blockquote } from '../../../components/Blockquote';
import { SidebarCategoryRoutes, TWILIO_RED } from '../../../constants';
import { PasteIcon } from '../../../components/icons/PasteIcon';
import Changelog from '@twilio-paste/media-object/CHANGELOG.md';
export const pageQuery = graphql`
  {
    allPasteLayout(filter: {name: {eq: "@twilio-paste/media-object"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/layout/media-object/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Media Object" categoryRoute={SidebarCategoryRoutes.LAYOUT} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/layout/media-object" storybookUrl="/?path=/story/layout-media-object--default" data={props.data.allPasteLayout.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Media Object`}</h3>
        <p>{`First created by `}<a parentName="p" {...{
            "href": "http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/"
          }}>{`Nicole Sullivan`}</a>{` back in 2010, the Media Object in Paste aims to serve many of the same purposes as the original concept. It's a layout pattern that you will see all across the web, on almost all the websites you will come across. We're pretty sure you'll `}<strong parentName="p">{`never unsee this going forward`}</strong>{`.`}</p>
        <Blockquote name="Nicole Sullivan" source="Stubbornella" sourceUrl="http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/" mdxType="Blockquote">
  What is the internet made of? At least the UI layer is mainly composed of media blocks.
        </Blockquote>
        <p><strong parentName="p">{`So what's a media object?`}</strong>{` An image or figure positioned horizontally next to some form of content. The figure can sit on either or both sides of the content. That's it. It sounds really simple, but it's literally everywhere on the Internet. For example, the top left of this website uses a Media Object.`}</p>
        <LivePreview scope={{
          PasteIcon,
          Text,
          MediaObject,
          MediaFigure,
          MediaBody,
          TWILIO_RED
        }} language="jsx" mdxType="LivePreview">
  {`<MediaObject verticalAlign="center">
  <MediaFigure spacing="space40">
    <PasteIcon color={TWILIO_RED} display="block" size={42} />
  </MediaFigure>
  <MediaBody>
    <Text as="h2" fontSize="fontSize60" lineHeight="lineHeight60">
      <Text href="/" as="a" color="inherit" fontSize="inherit" lineHeight="inherit" textDecoration="none">Paste</Text>
    </Text>
    <Text as="h3" fontSize="fontSize20" lineHeight="lineHeight20" color="colorTextWeak">
      Design System
    </Text>
  </MediaBody>
</MediaObject>`}
        </LivePreview>
        <p>{`If you were to draw the concept, it might look something like this:`}</p>
        <Box as="div" backgroundColor="colorBackgroundDark" padding="space40" marginBottom="space70" mdxType="Box">
  <MediaObject as="div" mdxType="MediaObject">
    <MediaFigure as="div" spacing="space40" mdxType="MediaFigure">
      <Box backgroundColor="colorBackgroundPrimary" height="sizeIcon110" width="sizeIcon110" mdxType="Box" />
    </MediaFigure>
    <MediaBody as="div" mdxType="MediaBody">
      <Box backgroundColor="colorBackgroundPrimary" height="sizeIcon110" width="100%" mdxType="Box" />
    </MediaBody>
  </MediaObject>
        </Box>
        <p>{`The aim of the Media Object to make this common layout pattern easy and efficient to implement.`}</p>
        <h4>{`Accessibility`}</h4>
        <p>{`Media Object has no specific accessibility concerns. While using this layout component, it is up to you to manage the resulting
accessibility implications. You may want to consider how the Media Object affects readability due to ordering, and
the visual or content hierarchy of your page.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Basic Media Object`}</h3>
        <LivePreview scope={{
          Box,
          MediaObject,
          MediaFigure,
          MediaBody
        }} language="jsx" mdxType="LivePreview">
  {`<MediaObject as="div">
  <MediaFigure as="div">
    <Box backgroundColor="colorBackgroundDark" borderRadius="borderRadius20" height="sizeIcon100" width="sizeIcon100" />
  </MediaFigure>
  <MediaBody as="div">
    Some Text
  </MediaBody>
</MediaObject>`}
        </LivePreview>
        <h3>{`MediaFigure Spacing`}</h3>
        <p>{`To set some spacing between the `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` and `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{`, set the `}<inlineCode parentName="p">{`spacing`}</inlineCode>{` property on the `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{`. This prop takes any spacing token.`}</p>
        <LivePreview scope={{
          Box,
          MediaObject,
          MediaFigure,
          MediaBody
        }} language="jsx" mdxType="LivePreview">
  {`<MediaObject as="div">
  <MediaFigure
    as="div"
    spacing="space40"
  >
    <Box backgroundColor="colorBackgroundDark" borderRadius="borderRadius20" height="sizeIcon100" width="sizeIcon100"></Box>
  </MediaFigure>
  <MediaBody as="div">
    Some Text
  </MediaBody>
</MediaObject>`}
        </LivePreview>
        <h3>{`Vertical alignment`}</h3>
        <p>{`By default the `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{` is top-aligned to the `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{`. By setting the `}<inlineCode parentName="p">{`verticalAlign`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`MediaObject`}</inlineCode>{` to "center", you can align the `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{` to the middle of the `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{`.`}</p>
        <LivePreview scope={{
          Box,
          MediaObject,
          MediaFigure,
          MediaBody
        }} language="jsx" mdxType="LivePreview">
  {`<MediaObject as="div" verticalAlign="center">
  <MediaFigure
    as="div"
    spacing="space40"
  >
    <Box backgroundColor="colorBackgroundDark" borderRadius="borderRadius20" height="sizeIcon100" width="sizeIcon100"></Box>
  </MediaFigure>
  <MediaBody as="div">
    Some Text
  </MediaBody>
</MediaObject>`}
        </LivePreview>
        <h3>{`MediaFigure at the end`}</h3>
        <p>{`To place the `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` after the `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{`, at the end of the `}<inlineCode parentName="p">{`MediaObject`}</inlineCode>{`, place the `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` after the `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{` in the DOM. Be sure to set the `}<inlineCode parentName="p">{`align`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` to "end" to reverse the spacing.`}</p>
        <LivePreview scope={{
          Box,
          MediaObject,
          MediaFigure,
          MediaBody
        }} language="jsx" mdxType="LivePreview">
  {`<MediaObject as="div" verticalAlign="center">
  <MediaBody as="div">
    Some Text
  </MediaBody>
  <MediaFigure
    as="div"
    align="end"
    spacing="space40"
  >
    <Box backgroundColor="colorBackgroundDark" borderRadius="borderRadius20" height="sizeIcon100" width="sizeIcon100"></Box>
  </MediaFigure>
</MediaObject>`}
        </LivePreview>
        <h3>{`Double figure`}</h3>
        <p>{`To have a `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` at either end of the `}<inlineCode parentName="p">{`MediaObject`}</inlineCode>{`, add a `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` before and after the `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{`, setting the second `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` to `}<inlineCode parentName="p">{`align="end"`}</inlineCode>{`.`}</p>
        <LivePreview scope={{
          Box,
          MediaObject,
          MediaFigure,
          MediaBody
        }} language="jsx" mdxType="LivePreview">
  {`<MediaObject as="div" verticalAlign="center">
  <MediaFigure
    as="div"
    spacing="space40"
  >
    <Box backgroundColor="colorBackgroundDark" borderRadius="borderRadius20" height="sizeIcon100" width="sizeIcon100"></Box>
  </MediaFigure>
  <MediaBody as="div">
    Some Text
  </MediaBody>
  <MediaFigure
    as="div"
    align="end"
    spacing="space40"
  >
    <Box backgroundColor="colorBackgroundDark" borderRadius="borderRadius20" height="sizeIcon100" width="sizeIcon100"></Box>
  </MediaFigure>
</MediaObject>`}
        </LivePreview>
        <h3>{`Example use cases`}</h3>
        <h4>{`The Alert component`}</h4>
        <p>{`The Alert is a classic Media Object layout. The status level icon is the Media Figure, and the alert description is the Media Body.`}</p>
        <LivePreview scope={{
          Alert
        }} language="jsx" mdxType="LivePreview">
  {`<Alert variant="warning">
  <strong>Chimamanda Ngozi Adichie:</strong> Racism should never have happened and so you don't get a cookie for reducing it.
</Alert>`}
        </LivePreview>
        <h4>{`A social feed item header`}</h4>
        <p>{`Here we're center aligning an avatar next to a timestamp and username.`}</p>
        <LivePreview scope={{
          Text,
          Avatar,
          MediaObject,
          MediaFigure,
          MediaBody
        }} language="jsx" mdxType="LivePreview">
  {`<MediaObject as="div" verticalAlign="center">
  <MediaFigure as="div" spacing="space40">
    <Avatar size="sizeIcon70" name="James Baldwin" />
  </MediaFigure>
  <MediaBody as="div">
    <Text as="div" color="colorTextWeak" fontSize="fontSize20" lineHeight="lineHeight10">
      3h
    </Text>
    <Text as="div" fontSize="fontSize30" fontWeight="fontWeightSemibold" lineHeight="lineHeight10">
      James Baldwin
    </Text>
  </MediaBody>
</MediaObject>`}
        </LivePreview>
        <h4>{`Chat log bubble`}</h4>
        <p>{`By placing the figure at the end of the Media Object and top aligning it to a chat bubble, we can start to compose a custom chat log UI.`}</p>
        <LivePreview scope={{
          Box,
          Text,
          Avatar,
          MediaObject,
          MediaFigure,
          MediaBody
        }} language="jsx" mdxType="LivePreview">
  {`<Box display="flex" justifyContent="flex-end">
  <MediaObject as="div">
    <MediaBody as="div">
      <Box
        as="div"
        color="colorTextInverse"
        backgroundColor="colorBackgroundPrimary"
        borderRadius="borderRadius20"
        borderTopRightRadius="borderRadius0"
        fontSize="fontSize30"
        lineHeight="lineHeight20"
        padding="space30"
        maxWidth="size30"
      >
        Racism should never have happened and so you don't get a cookie for reducing it.
      </Box>
      <Text as="div" color="colorTextWeak" fontSize="fontSize20" textAlign="right">
        Chimamanda Ngozi Adichie
      </Text>
    </MediaBody>
    <MediaFigure as="div" align="end" spacing="space40">
      <Avatar size="sizeIcon50" name="Chimamanda Ngozi Adichie" />
    </MediaFigure>
  </MediaObject>
</Box>`}
        </LivePreview>
        <h2>{`Composing a Media Object`}</h2>
        <p>{`The Media Object is a composition of three things;`}</p>
        <ul>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`MediaObject`}</inlineCode></li>
          <li parentName="ul">{`A `}<inlineCode parentName="li">{`MediaFigure`}</inlineCode></li>
          <li parentName="ul">{`A `}<inlineCode parentName="li">{`MediaBody`}</inlineCode></li>
        </ul>
        <p>{`The `}<inlineCode parentName="p">{`MediaObject`}</inlineCode>{` acts as the outer wrapper of the pattern. The `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` is the container for the content that is often graphical in nature and fixed in size. The `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{` is a container typically for accompanying text content and fills the remaining space.`}</p>
        <p>{`The content of a `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` and `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{` is entirely up to you.`}</p>
        <p>{`The size of `}<inlineCode parentName="p">{`MediaFigure`}</inlineCode>{` is determined by the content that is placed inside of it. `}<inlineCode parentName="p">{`MediaBody`}</inlineCode>{` will fill the remaining space in the row.`}</p>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`Installation`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/media-object
`}</code></pre>
        <h3>{`Usage`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {MediaObject, MediaFigure, MediaBody} from '@twilio-paste/media-object';

const Component = (props) => (
  <MediaObject verticalAlign="center">
    <MediaFigure spacing="space40">...</MediaFigure>
    <MediaBody>...</MediaBody>
  </MediaObject>
);
`}</code></pre>
        <h3>{`API`}</h3>
        <h4>{`MediaObject Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`keyof JSX.IntrinsicElements`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'span'`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginBottom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/tokens/#spacings"
                }}>{`Spacing`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginTop`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/tokens/#spacings"
                }}>{`Spacing`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`verticalAlign`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`center`}</inlineCode>{`, `}<inlineCode parentName="td">{`top`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Aligns the figure and body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`MediaFigure Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`align`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`start`}</inlineCode>{`, `}<inlineCode parentName="td">{`end`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'start'`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`keyof JSX.IntrinsicElements`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'span'`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`spacing`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/tokens/#spacings"
                }}>{`Spacing`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Space between the figure and body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`MediaBody Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`keyof JSX.IntrinsicElements`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'span'`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      